import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { EnterAwareTextField, FormLayout } from '../elements';
import { useFormPage, useFormPath, useUserRole } from '../hooks';
import { ContactMethod } from '../types/ContactMethod';
import { FormValues } from '../types/FormValues';
import { Vastauskoodi } from '../types/Vastauskoodi';
import { FormPath, getNextPath } from './formPaths';

const schema = yup.object().shape({
  vastauskoodi: yup.string().oneOf(Object.values(Vastauskoodi)).required(),
  yhteydenottotapa: yup.string().oneOf(Object.values(ContactMethod)).required(),
  vastauskoodi_lisatieto: yup.string().optional(),
});

type VastauskoodiValues = Pick<
  FormValues,
  'vastauskoodi' | 'vastauskoodi_lisatieto' | 'yhteydenottotapa'
>;

export default function VastauskoodiPage() {
  const currentFormPath = useFormPath();
  const { isAdmin } = useUserRole();
  const { t } = useTranslation();
  const { control, handleSubmit, syncing } = useFormPage<VastauskoodiValues>({
    schema,
    defaultValues: {
      vastauskoodi: Vastauskoodi.INFORMATION_AVAILABLE,
    },
    routes: {
      next: (data) =>
        data.vastauskoodi === Vastauskoodi.INFORMATION_AVAILABLE
          ? getNextPath(currentFormPath)!
          : FormPath.YHTEENVETO,
    },
    fields: ['vastauskoodi', 'vastauskoodi_lisatieto', 'yhteydenottotapa'],
  });

  if (!isAdmin) {
    return <Redirect to={FormPath.ASUNTOKUNTA} />;
  }

  return (
    <FormLayout
      heading={t('vastauskoodit.title')}
      syncing={syncing}
      onSubmit={(e) => void handleSubmit(e)}
    >
      <Box p={3}>
        <Controller
          name="yhteydenottotapa"
          control={control}
          defaultValue={null}
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <FormLabel id="contacted-by-label">
                {t('vastauskoodit.contactedByControl.label')}
              </FormLabel>

              <RadioGroup {...field} aria-labelledby="contacted-by-label">
                <FormControlLabel
                  disabled={syncing}
                  value={ContactMethod.EMAIL}
                  control={<Radio />}
                  label={t('vastauskoodit.contactedByControl.option1')}
                />
                <FormControlLabel
                  disabled={syncing}
                  value={ContactMethod.PHONE}
                  control={<Radio />}
                  label={t('vastauskoodit.contactedByControl.option2')}
                />
                <FormControlLabel
                  disabled={syncing}
                  value={ContactMethod.MAIL}
                  control={<Radio />}
                  label={t('vastauskoodit.contactedByControl.option3')}
                />
              </RadioGroup>

              {!!error && (
                <FormHelperText error data-testid={`${field.name}-error`}>
                  {t('vastauskoodit.contactedByControl.error')}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />

        <Box my={3}>
          <Controller
            name="vastauskoodi"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <FormLabel id="vastauskoodi-label">
                  {t('vastauskoodit.vastauskoodiControl.label')}
                </FormLabel>

                <RadioGroup {...field} aria-labelledby="vastauskoodi-label">
                  <FormControlLabel
                    disabled={syncing}
                    value={Vastauskoodi.RESPONSE_REJECTED}
                    control={<Radio />}
                    label={t('vastauskoodit.vastauskoodiControl.option1')}
                  />
                  <FormControlLabel
                    disabled={syncing}
                    value={Vastauskoodi.PAPER_RESPONSE_RECEIVED}
                    control={<Radio />}
                    label={t('vastauskoodit.vastauskoodiControl.option2')}
                  />
                  <FormControlLabel
                    disabled={syncing}
                    value={Vastauskoodi.RESPONDENT_REFUSED}
                    control={<Radio />}
                    label={t('vastauskoodit.vastauskoodiControl.option3')}
                  />
                  <FormControlLabel
                    disabled={syncing}
                    value={Vastauskoodi.INFORMATION_AVAILABLE}
                    control={<Radio />}
                    label={t('vastauskoodit.vastauskoodiControl.option4')}
                  />
                </RadioGroup>

                {!!error && (
                  <FormHelperText error data-testid={`${field.name}-error`}>
                    {t('vastauskoodit.vastauskoodiControl.error')}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Box>

        <Controller
          name="vastauskoodi_lisatieto"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <EnterAwareTextField
              {...field}
              label={t('vastauskoodit.vastauskoodi_lisatieto')}
              disabled={syncing}
              error={!!error}
              fullWidth
            />
          )}
        />
      </Box>
    </FormLayout>
  );
}
